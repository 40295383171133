﻿.banner_adjusted {
    margin-top: 10px;
}

.maintenance-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff4e5;
    color: #8b572a;
    border-bottom: 1px solid #f0e1cc;
    font-size: 14px;
    border-radius: 10px;
    margin-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;

    .alert-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .extra-padding {
        padding: 10px;
    }

    .link-padding {
        padding: 0 22px;
    }

    .text-container {
        padding: .75rem .5rem;
    }
}

.maintenance-icon {
    margin-right: 10px;
}

.maintenance-text {
    display: inline-flex;
    padding-right: .5rem;
}

.dismiss-link {
    color: #8b572a;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    position: relative;
    display: inline-flex;
}

@media (min-width: 576px) {
    .maintenance-banner {
        flex-direction: row;
        align-items: center;
    }

    .dismiss-link {
        align-self: auto;
        display: inline-flex;
    }
}