@import '_mixins.scss';

$background-color: white !default;
$font-family-body: inherit !default;

.language-selector {
  display: flex;

  &-overlay {
    inset: 0;
    position: fixed;
    background: black;
    z-index: 1;
    opacity: 0.15;
    &.hidden {
      display: none;
      visibility: hidden;
    }
  }

  &--icon {
    width: 26px;
    height: 26px;
  }

  &--name {
    color: black;
    font-size: 18px; // fix it later
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.72px;
    word-wrap: break-word;

    margin-left: 4px;
    padding-block: 4px;
  }
  

  &--button {
    width: 26px;
    height: 26px;
    background: transparent;
    margin: 0;
    margin-left: 8px;
    padding: 0;

    &[aria-pressed="true"],
    &[aria-pressed] {
      background-image: url("data-url:../img/icons/expand_circle_down-opened.svg");
    }

    &[aria-pressed="false"] {
      background-image: url("data-url:../img/icons/expand_circle_down.svg");
    }
  }

  &--container {
    background: $background-color;

    border-top: 1px solid black;
    border-bottom: 1px solid black;

    position: absolute;
    z-index: 1000;

    max-width: min(100vw, max(400px, 37%));
    width: 100%;

    top: 80px;
    right: 35px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;

    padding-block: 28px;
    padding-left: 28px;
    padding-right: 40px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;

    &.hidden {
      display: none;
      visibility: hidden;
    }

    &-column {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    
    .language-selector--countries {
      &-section {
      }

      &-header {
        color: black;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.72px;
        font-family: $font-family-header;
        word-wrap: break-word;

        margin: 0;
        padding: 0;
      }

      &-list {
        list-style: none;
        margin: 0;
        padding: 0;

        a {
          color: black;
          font-family: $font-family-body;
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          text-decoration: none;

          .native-name {
            color: #767676;
            font-size: 0.8em;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@include for-large-desktop() {
  .language-selector--container {
    top: 90px;
    max-height: calc(100vh - 120px);
    max-width: min(100vw,max(400px,40%));
  }
}

@include for-tablet() {
  .language-selector--name {
    font-size: 16px;
  }
  .language-selector--container {
    max-width: 100vw;
    right: 0;
    width: 100%;

    // logo image width is 20vw with aspect ratio 1:3 + margin
    top: calc(20vw * 0.3 + 40px);
    max-height: unset;
    height: unset;
    overflow-y: unset;
  }
}

@include for-mobile() {
  .language-selector--container {
    flex-direction: column;
  }
  .language-selector--list {
    grid-template-columns: 1fr;
    left: 15px;
    right: 15px;

    // logo image width is 20vw with aspect ratio 1:3 + margin
    top: calc(20vw * 0.3 + 35px);
  }
  .language-selector--icon {
    width: 10vw;
  }
}

@include for-small-mobile() {
  .language-selector--icon,
  .language-selector--name {
    display: none;
    visibility: hidden;
  }

  .language-selector--button {
    width: 32px;
    height: 32px;
  }
}
