﻿@charset 'UTF-8';
@import '_fonts.scss';
@import '_settings.scss';
@import '../_mixins.scss';

:root {
    --scrollbar-background: transparent;
    --scrollbar-thumb-background: #e8e8e8;
    --scrollbar-width: 8px;
}

::-webkit-scrollbar {
    width: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
}

::-webkit-scrollbar-thumb {
    border: 2px solid $white;
    background-color: var(--scrollbar-thumb-background);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    border: 3px solid var(--scrollbar-thumb-background);
}

/* BEGIN: Containers */

html,
body {
    font-family: FFMetaWebNormal;
    margin: 0;
    padding: 0;
}

body {
    background: $background-color;
    color: $black;
    padding: 20px;
    overflow-x: hidden;
}

.header {
    padding-left: 3px;
    padding-right: 0;
    margin-bottom: 10px;

    & .language-selector {
        margin-top: 15px;
    }
}

.footer {
    font-size: 0.75rem;
    font-family: Verdana, sans-serif;
    color: $gray;
    height: 25px;

    & div {
        align-self: flex-end;
    }
}

.page {
    width: 1440px;
    max-height: 1200px;
    margin: auto;
    align-items: center;
    box-shadow: 0px 8px 60px 0px rgb(16 40 88 / 15%);
    -webkit-box-shadow: 0px 8px 60px 0px rgb(16 40 88 / 15%);
    -moz-box-shadow: 0px 8px 60px 0px rgba(16, 40, 88, 0.15);
    border-radius: 10px;
    padding: 13px 60px 25px;
    background: $background-color;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
}

.container {
    overflow-y: auto;
}

.page-part-left {
    background: url(../img/Illustration-Unitron.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    max-width: 744px;
    min-height: 600px;
    height: calc(100% - 20px);
    display: flex;
    border-radius: 10px;
    margin-bottom: 17px;
}

.page-part-left h1 {
    font-size: 2em;
    font-weight: bold;
}

.page-part-left .container-fluid.d-flex.flex-column.justify-content-between {
    padding: 1.5em 0.75em;
}

.page-part-left .container-fluid.d-flex.flex-column.justify-content-between div:nth-child(2) > div {
    font-size: 0.875em;
}

.page-part-left .container-fluid.d-flex.flex-column.justify-content-between div:nth-child(2) a h5 {
    font-size: 1em;
    margin: 0.5em 0px 0px;
}

.page-part-right {
    background: $background-color;
    padding: 0 0 1.5rem;
    border-radius: 0 10px 10px 0;
    display: flex;

    & .input-group .form-control {
        border: none;
    }
}

.page .input-group:focus-within .input-group-text,
.page .input-group:focus-within .form-control:focus,
.page .input-group:focus-within .form-select:focus {
    border: none !important;
    box-shadow: none !important;
}

.page .input-group:focus-within {
    color: #495057;
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $selection-accent;

    &:has(.input-validation-error) {
        border-color: $primary-color;
    }
}

.page-part-right .d-flex.justify-content-center {
    width: 100%;
}

.page-part-right .d-flex.justify-content-center > div {
    width: 400px;
}

.content {
    vertical-align: top;
    height: 100%;
}

section {
    margin-top: 1.5rem;

    & p > .subtitle {
        margin-top: 1rem;
    }
}

/* CLOSE: Containers */

/* BEGIN: Images (includes icons & logo) */

.bi {
    height: 24px;
    width: 24px;
}

.logo {
    margin: 5px 0 25px;
    width: 150px;
}

/* CLOSE: Images */

/* BEGIN: Text */

p {
    font-size: $p2-font-size;
    line-height: $p2-line-height;
    margin-bottom: 1rem;
    letter-spacing: 0.05rem;

    & > .subtitle {
        margin: 12px 0 5px 0;
        font-family: 'FFMetaWebBold';
        display: block;
    }

    &.small {
        font-size: $p3-font-size;
        line-height: $p3-line-height;
        margin-bottom: 1rem;

        a {
            display: inline-block;
            margin-bottom: 1rem;
        }
    }

    &.caption {
        font-size: $p4-font-size;
        line-height: $p4-line-height;
        margin-bottom: 2.38rem;
        letter-spacing: .035rem;
    }
}

.title {
    font-family: 'FFMetaWebBold';
    font-style: normal;
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    margin-bottom: .5rem;
}

/* CLOSE: Text */

/* BEGIN: Forms (including buttons) */

form {
    padding-top: 1rem;
}

label {
    cursor: default;
}

.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;
    align-items: center;

    input {
        padding: 16px;
        font-size: 1.2rem;
        letter-spacing: 0.08rem;

        &.form-control {
            height: 3.5rem;
            flex-shrink: 0;
            border-radius: 3px;
            border: 1px solid $default-border-color;
            background-color: $white;
            padding: 14px 0 8px 16px;

            &:focus {
                border-color: $primary-color;
            }
        }

        &:disabled {
            border-bottom: 1px solid $light-gray;

            & ~ label {
                color: $light-gray;
            }
        }

        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            top: -22px;
            font-size: 14px;
            color: $primary-color;
            transition: 0.1s;
            line-height: 18px;
            letter-spacing: 0;
            left: 0;
        }

        &:not(:focus):not(:placeholder-shown) ~ label {
            color: $dark-gray;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 40rem $autofill-accent inset;
            -webkit-text-fill-color: $black;
        }
    }

    label {
        height: 10px;
        pointer-events: none;
        position: absolute;
        transform-origin: 0 50%;
        transition: transform 200ms, color 200ms;
        top: 19px;
        left: 18px;
        color: $gray;
        line-height: normal;
        vertical-align: middle;
    }

    & input.form-control.input-validation-error {
        border-color: $alert;

        &:focus {
            border-color: $primary-color;
        }
    }
}

.validation {
    margin-top: 5px;
    color: $alert;
    text-align: right;

    .error {
        margin-top: 0;
    }
}

.alert {
    padding: 0;
    margin-bottom: 5px;
    font-size: 1.125rem;
    color: $alert;
    background-color: transparent;
    border-color: transparent;

    & ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    & .validation-summary-errors {
        margin-top: -5px;
    }
}

.alert:has(.validation-summary-errors) + form {
    & > .form-group {
        & > .form-control:not(:focus), > .input-group:not(:focus-within) {
            border-color: $alert;
        }
    }
}

div.alert > div #RequestData_Username.form-control {
    border: 1px solid $alert !important;
    box-shadow: none;
}

div.alert > div .input-group {
    border: 1px solid $alert !important;
    box-shadow: none !important;
}

::selection {
    background: $selection-accent;
}

.form-control:focus {
    color: #495057;
    border-color: $primary-color;
    box-shadow: 0 0 0 0.2rem $selection-accent;
}

.input-group {
    border: 1px solid $light-gray;
    border-color: $light-gray;
    border-radius: 5px;

    & input {
        padding: 8px 0 8px 8px;
    }
}

.form-group:has(.field-validation-error) .input-group {
    border-color: $alert;
}

.input-group-text {
    background: $white;
    border: none;
    padding: 5px 10px 0 7px;
}

.clickable {
    cursor: pointer;
}

button:focus {
    outline: none;
}

button, .button {
    border-radius: $button-radius;
    border-width: 0;
    margin: $global-margin 0 0 0;
    width: 100%;
    cursor: pointer;
    text-align: center;
    letter-spacing: 0.04em;
    font-family: 'FFMetaWebBold';
    font-size: map-get($button-font-size, default);
    padding: 17px 25px 13px;

    &.primary {
        background-color: $button-background-primary;
        color: $button-color-primary;

        &:hover:not(:disabled):not(:active):not(:focus) {
            background-color: $accent-bright;
        }

        &:focus:not(:active):not(.hide-focus) {
            background-color: $accent-bright;
        }

        &:focus:not(:focus-visible):not(:active):not(.hide-focus) {
            background-color: $accent-bright;
        }

        &:focus-visible:not(.hide-focus) {
            background-color: $accent-bright;
        }

        &:active:enabled {
            background-color: $primary-color;
        }

        &.hide-focus:hover:not(:active):enabled {
            background-color: $active-control-color;
        }

        &.disabled {
            background-color: $button-background-disabled;
            color: $button-color-disabled;
            cursor: default;
            box-shadow: none;
        }
    }

    &.secondary {
        background-color: $button-background-secondary;
        border: 2px solid $button-color-secondary;
        text-decoration: none;
        display: block;

        &:hover:not(:active):not(.hide-focus):enabled,
        &:focus:not(:active):not(.hide-focus):enabled {
            background-color: $active-control-color;
        }

        &.hide-focus:hover:not(:active):enabled {
            background-color: $active-control-color;
        }

        &:active {
            background-color: $darker-gray;
        }

        &.disabled {
            cursor: default;
            box-shadow: none;
            opacity: 0.5;
            background-color: transparent;
        }
    }

    &.large {
        font-size: map-get($button-font-size, large);
        padding: map-get($button-padding, large);
    }

    &.small {
        font-size: map-get($button-font-size, small);
        padding: map-get($button-padding, small);
    }
}

/* BEGIN: Checkbox */

.form-checkbox {
    display: inline-flex;
    gap: 0px;
    cursor: pointer;
    font-size: $p3-font-size;
    line-height: $p3-line-height;
    color: $neutral-03;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    & input:checked ~ .checkmark:after {
        display: block;
    }

    & input:checked ~ .checkmark {
        box-shadow: $primary-color 0px 0px 0px 0px;
        background-color: $primary-color;
        height: 26px;
        width: 26px;
        flex-shrink: 0;
        margin-top: -1px;
        transition: all 0.2s ease 0s;
        cursor: pointer;
        transform-origin: 0px 10px;
        border-radius: 4px;
        margin: -1px 10px 0px 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    & .checkmark:after {
        left: 0.55em;
        top: 0.25em;
        width: 0.5em;
        height: 0.9em;
        border: solid white;
        border-width: 0 0.175em 0.175em 0;
        transform: rotate(45deg);
        transition: all 500ms ease-in-out;
    }
}

.checkmark {
    position: relative;
    box-shadow: $light-gray 0px 0px 0px 1px;
    height: 26px;
    width: 26px;
    flex-shrink: 0;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    transform-origin: 0px 10px;
    border-radius: 0.25rem;
    margin: -1px 10px 0px 0px;
    padding: 0px;
    box-sizing: border-box;

    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}

/* CLOSE: Checkbox */

a {
    text-decoration: underline;
    color: $black;
    font-family: 'FFMetaWebNormal';

    &:hover {
        color: $primary-color;
    }

    &.button {
        text-decoration: none;

        &:hover:not(:disabled):not(:active):not(:focus):enabled {
            background-color: $accent-bright;
        }

        &.primary {
            display: block;
            background-color: $button-background-primary;
        }

        &.secondary {
            background-color: $button-background-secondary;
            color: $button-color-secondary;
            border: 1px solid $button-color-secondary;
            text-decoration: none;
            margin-top: 0;
            padding: 12px 25px 8px;

            &:focus:not(:active):not(.hide-focus),
            &:hover:not(:active) {
                background-color: $active-control-color;
                color: $button-color-secondary;
            }

            &:active {
                background-color: $darker-gray;
                color: $white;
            }
        }

        &:hover:not(:active) {
            background-color: $active-control-color;
            color: $button-color-secondary;
        }

        &:active {
            background-color: $primary-color;
        }

        &.small {
            display: inline;
        }
    }
}

.list-inline {
    margin-top: 12px;
    text-align: center;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.25rem;
}

/* CLOSE: Forms */

/* BEGIN: Responsive */

@include for-desktop {
    .page {
        margin: auto;
        width: 100%;
        padding: 20px 20px 30px 20px;
    }

    .header {
        padding-left: 0;
    }

    .logo {
        margin: 0 0 1.1em;
    }

    .page-part-right {
        padding: 0;
    }
}

@media (max-height: 900px) {
    .page {
        bottom: unset;
    }
}

@include for-tablet {
    body {
        background-color: $background-color;
    }

    .page {
        display: block;
        height: auto;
        position: relative;
        margin: 0;
        width: 100%;
        padding: 0 15px 10px;
        border-radius: 0;
        box-shadow: none;
    }

    .page-part-left .container-fluid.d-flex.flex-column.justify-content-between {
        padding-top: 0.5em;
        padding-bottom: 3.5em;
    }

    .page .header .logo {
        width: 25vw;
    }

    .header {
        padding-left: 0;

        .language-selector--container {
            top: calc(6vw + 30px);
        }

        & .language-selector {
            margin-top: 0;
        }
    }

    .container {
        max-width: unset;
    }

    .footer {
        text-align: center;
        margin: 20px 0 5px;
    }

    .logo {
        margin: 5px 0 15px 3px;
    }

    .col-12.order-2.col-md-5.order-md-2 {
        padding-left: 0;
        padding-right: 0;
    }

    .page-part-left {
        background-position: 50% -140px;
        min-height: 330px;
    }

    .page-part-right {
        border-radius: 0 0 10px 10px;
        height: auto;
        padding: 7px;

        .title {
            font-size: 35px;
            line-height: 120%;
        }
    }

    p {
        font-size: $p3-font-size;
        line-height: $p3-line-height;

        &.small {
            font-size: $p4-font-size;
            line-height: $p4-line-height;
        }
    }

    .caption, .list-inline {
        font-size: $p4-font-size;
        line-height: $p4-line-height;
    }

    .form-group {
        & input.form-control {
            height: 2.65rem;
            padding: 8px 0 4px 12px;
        }

        & label {
            top: 11px;
            left: 15px;
        }
    }
}

@media (max-width: 450px) {
    .page-part-left {
        min-height: 220px;
        background-position-y: -85px;
    }
}

@include for-mobile {
    body {
        padding: 0;
    }

    .header {
        .logo {
            margin: 15px 0 10px 3px;
        }

        & .language-selector {
            margin-top: 10px;

            & .language-selector--icon {
                width: 5vw;
            }

            & .language-selector--name {
                padding-top: 5px;
                font-size: 14px;
            }

            .language-selector--button {
                width: 20px;
                height: 20px;
                margin: 3px 0 0 3px;
            }
        }
    }

    section {
        margin-top: 1rem;
    }

    .page-part-left {
        min-height: 160px;
        background-position-y: -100px;
    }

    p {
        margin-bottom: 1.3rem;
        font-size: $p3-font-size;
        line-height: $p3-line-height;
    }

    section p > .subtitle {
        margin-top: 1rem;
    }
}


/* CLOSE: Responsive */
